/*
Copyright 2017-present Palantir Technologies, Inc. All rights reserved.
Licensed under the Apache License, Version 2.0.
*/
@font-face {
  font-family: "blueprint-icons-16";
  src: url("./blueprint-icons-16.ttf?91982b85a03a8db75b33eba7a49e8205") format("truetype"), url("./blueprint-icons-16.eot?91982b85a03a8db75b33eba7a49e8205#iefix") format("embedded-opentype"), url("./blueprint-icons-16.woff2?91982b85a03a8db75b33eba7a49e8205") format("woff2"), url("./blueprint-icons-16.woff?91982b85a03a8db75b33eba7a49e8205") format("woff"), url("./blueprint-icons-16.svg?91982b85a03a8db75b33eba7a49e8205#blueprint-icons-16") format("svg");
}
@font-face {
  font-family: "blueprint-icons-20";
  src: url("./blueprint-icons-20.ttf?7de71842b2d6bb6425f92a5ce59e42e3") format("truetype"), url("./blueprint-icons-20.eot?7de71842b2d6bb6425f92a5ce59e42e3#iefix") format("embedded-opentype"), url("./blueprint-icons-20.woff2?7de71842b2d6bb6425f92a5ce59e42e3") format("woff2"), url("./blueprint-icons-20.woff?7de71842b2d6bb6425f92a5ce59e42e3") format("woff"), url("./blueprint-icons-20.svg?7de71842b2d6bb6425f92a5ce59e42e3#blueprint-icons-20") format("svg");
}